import * as React from 'react';

import { Text, BlogImage, BlogLink } from '../../../components';

import { PageNew, SectionBlog } from '../../../fragments';

import luka from '../../../images/educational-corner/blog32/luka.jpg';
import kokolingoMap from '../../../images/educational-corner/blog32/kokolingo-karta.png';
import mobileVersion from '../../../images/educational-corner/blog32/mobilna-verzija.png';
import listenAgain from '../../../images/educational-corner/blog32/poslusaj-ponovo.png';
import subscription from '../../../images/educational-corner/blog32/pretplata.png';
import luckyEducationalCorner from '../../../images/educational-corner/blog32/srecko-edukativni-kutak.png';

const KokolingoForMobiles = () => {
  return (
    <PageNew
      title="KOKOLINGO - Kako je nastao Kokolingo za mobitele?"
      description="Ovoga tjedna donosimo vam razgovor s Lukom Bracanovićem, 
      tehničkim direktorom naše tvrtke E-Glas, koja se može pohvaliti s već 
      12 godina rada na polju asistivne tehnologije i potpomognute komunikacije. 
      S nama je podijelio novosti o prilagodbi logopedske aplikacije Kokolingo 
      za mobitele, kao i o najnovijim izmjenama aplikacije kako bi iskustvo 
      korisnika bilo još bolje. Poslušajte iz prve ruke koje vam novosti 
      donosimo i kako izgleda rad u pozadini šarene aplikacije, kao i kako je 
      tekao njezin razvojni put, od prve ideje do današnje verzije za korisnike."
      headline="Kokolingo - mobilna verzija"
    >
      <BlogImage
        imgAlt="Luka Bracanović"
        imgTitle="Luka Bracanović"
        imgSrc={luka}
      />
      <Text as="p">
        Ovoga tjedna donosimo vam razgovor s Lukom Bracanovićem, tehničkim
        direktorom naše tvrtke E-Glas, koja se može pohvaliti s već 12 godina
        rada na polju asistivne tehnologije i potpomognute komunikacije. S nama
        je podijelio novosti o prilagodbi logopedske aplikacije{' '}
        <BlogLink>Kokolingo</BlogLink> za mobitele, kao i o najnovijim izmjenama
        aplikacije kako bi iskustvo korisnika bilo još bolje. Poslušajte iz prve
        ruke koje vam novosti donosimo i kako izgleda rad u pozadini šarene
        aplikacije, kao i kako je tekao njezin razvojni put, od prve ideje do
        današnje verzije za korisnike.
      </Text>
      <Text as="h2">Kako razvijamo kokolingo?</Text>
      <Text as="p">
        Inspiracije i ideje dobivamo prvenstveno slušajući korisnike. U E-glasu
        pridajemo tome dodatan značaj. Ipak, za razliku od drugih tvrtki,
        softver razvijamo s ciljem pomaganja ljudima. U nastavku navodim ključne
        novitete koji su proizašli iz razgovora s našim korisnicima.
      </Text>
      <Text as="h2">Novosti - poboljšanja</Text>
      <Text as="h4">Poslušaj ponovno</Text>
      <Text as="p">
        Jako bitan segment logopedskih vježbi jest ponavljanje iste vježbe više
        puta, sve dok dijete ne ponovi ispravno. Novim dizajnom sučelje smo
        nastojali učiniti intuitivnijim za korisnike; olakšati roditelju i
        djetetu ponavljanje s ciljem što boljeg učenja.
      </Text>
      <BlogImage
        imgAlt="Poslusaj ponovo"
        imgTitle="Poslusaj ponovo"
        imgSrc={listenAgain}
      />
      <Text as="h4">Nova karta</Text>
      <Text as="p">
        Novi dizajn značajno pojednostavljuje “navigaciju”, naglasili smo korake
        koji sugeriraju redoslijed aktivnosti korisnicima. Aktivnosti na karti
        poslagane su od težih prema lakšima, tako da korisnici svaki dan mogu
        krenuti s laganim “zagrijavanjem” prema težim vježbama.
      </Text>
      <BlogImage
        imgAlt="Nova Kokolingo karta"
        imgTitle="Nova Kokolingo karta"
        imgSrc={kokolingoMap}
      />
      <Text as="h4">Srećkov edukativni kutak</Text>
      <Text as="p">
        <BlogLink>Kokolingo</BlogLink> uz zabavne vježbe ima velik broj
        edukativnih video materijala koje smo pomaknuli u poseban edukativni
        kutak radi lakšeg pristupanja vježbama i istraživanju. U svakom trenutku
        Srećko stoji na raspolaganju u navigacijskoj traci sa svojim korisnim
        savjetima.
      </Text>
      <BlogImage
        imgAlt="Srećkov edukativni kutak"
        imgTitle="Srećkov edukativni kutak"
        imgSrc={luckyEducationalCorner}
      />
      <Text as="h4">Jednostavnija pretplata</Text>
      <Text as="p">
        Za nas koji razvijamo Kokolingo iznimno je bitan segment zadovoljstva
        korisnika aplikacijom i mogućnost odabira prikladnog paketa pretplate.
        Upravo kada korisnik postane <b>pretplatnik</b> dobivamo potvrdu da je
        zadovoljan i to nas gura u daljnji razvoj. Pojednostavili smo odabir
        pretplate u par jednostavnih koraka i dodali završni pregled. Podržavamo
        plaćanje karticom i putem banke.
      </Text>
      <BlogImage
        imgAlt="Jednostavnija pretplata"
        imgTitle="Jednostavnija pretplata"
        imgSrc={subscription}
      />
      <Text as="h4">Mobilna verzija</Text>
      <Text as="p">
        Najznačajnija promjena je prilagodba aplikacije mobitelima. Prateći
        aktivnosti i želje naših korisnika, usmjereni smo prema mobilnoj verziji
        aplikacije. Gotovo 70% naših korisnika
        <BlogLink> Kokolingo</BlogLink> koristi putem mobilnih uređaja, zbog
        čega smo velik dio energije uložili u definiranje mobilnog stranica
        pomno je razrađena. Sučelje na mobitelima je pojednostavljeno i
        orijentirano za vertikalan (portrait) način rada kako bi vježbanje bilo
        lakše.
      </Text>
      <BlogImage
        imgAlt="Mobilna verzija"
        imgTitle="Mobilna verzija"
        imgSrc={mobileVersion}
      />
      <Text as="h2">Proces razvoja aplikacije</Text>
      <Text as="p">
        Nakon inicijalne verzije Kokolinga pred devet mjeseci, iterativno u
        ciklusima od dva tjedna do mjesec dana objavljujemo nove verzije. Prva
        verzija je temelj i rezultat je suradnje razvojnog tima, dizajnera i
        fokus grupe logopeda. Od skice na papiru do “žive” aplikacije prošlo je
        nešto više od šest mjeseci.
      </Text>
      <Text as="p">
        Razvojni proces danas fokusiran je na iteracije koje obuhvaćaju nekoliko
        koraka:
      </Text>
      <Text as="p">
        <ol>
          <li>
            <b>IDEJA</b>
            <Text as="p">
              Najčešće proizlazi iz komentara korisnika i njihovih navika
            </Text>
          </li>
          <li>
            <b>RAZRADA IDEJE</b>
            <Text as="p">
              Razgovor razvojnog tima, dizajnera i menadžera. U ovom koraku vode
              se žustre bitke kojim smjerom krenuti i koju ideju odabrati prvu
              :)
            </Text>
          </li>
          <li>
            <b>DIZAJN</b>
            <Text as="p">
              Nakon što se prašina slegne, dizajner razrađuje, “crta”, kako
              ideja treba izgledati. U određenim slučajevima rade se vizualni
              prototipi radi validacije.
            </Text>
          </li>
          <li>
            <b>RAZVOJ</b>
            <Text as="p">
              Oživljavamo ideju s “papira” u interaktivnu aplikaciju. Tim
              programera zajedno programira potrebne komponente sustava.
            </Text>
          </li>
          <li>
            <b>TESTIRANJE</b>
            <Text as="p">
              U ovom koraku provjeravamo je li aplikacija interaktivna na
              ispravan način, odgovara li dizajnu i prethodno dogovorenim
              zahtjevima.
            </Text>
          </li>
          <li>
            <b>FEEDBACK</b>
            <Text as="p">
              Ponovno osluškujemo komentare korisnika i započinjemo isti proces
              ispočetka, jer aplikacija nikad nije gotova.
            </Text>
          </li>
        </ol>
      </Text>
      <Text as="p">
        Možete primijetiti da zapravo vrlo često razgovaramo s korisnicima, čije
        nam je mišljenje jako bitno. Svaki je komentar dobrodošao.
      </Text>
      <Text as="p">
        A kako ste vi zadovoljni <BlogLink>Kokolingom</BlogLink>? :)
      </Text>
      <Text as="p">
        Svoje dojmove možete podijeliti s nama u našoj{' '}
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLScOBscQ27ML0kir9k5PZScOy74g69XHsTkCc3zA7xkslRrGhQ/viewform"
          target="_blank"
          rel="noopener noreferrer"
        >
          <b>online anketi</b>
        </a>
        .
      </Text>
      <SectionBlog />
    </PageNew>
  );
};

export default KokolingoForMobiles;
